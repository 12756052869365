/* Copyright (C) nexleader - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written for nexleader <myipsat.com>, 2016-2018
 */

/*global angular*/
import { AuthService } from './../../../../services/auth.service';
import { BlobResourceService } from './../../../../services/blob.service';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from '../../../../services/group.service';
import { EmailService } from '../../../../services/email.service';
import { UserService } from '../../../../services/user.service';
import { EnumsService } from '../../../../services/enum.service';
import { SuccessService } from '../../../../services/success.service';
import { ErrorHandlerService } from '../../../../services/error-handler.service';
import { catchError, of, tap } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgModelDebounceChangeDirective } from '../../../../directives/ng-model-debounce.directive';
import { FormsModule } from '@angular/forms';
import { NexleaderGroupPhotoUploadComponent } from '../../components/group-photo-upload/group-photo-upload.component';
import { NexleaderInputEnumComponent } from '../../components/input-enum/input-enum.component';
import { NexleaderGroupDirectPurchaseSettingsComponent } from '../../../direct/components/group-direct-purchase-settings/group-direct-purchase-settings.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NexleaderCustomOnboardingPreviewModalComponent } from '../../components/modals/custom-onboarding-preview-modal/custom-onboarding-preview-modal.component';
import { NexleaderLearnAboutCustomVideoModalComponent } from '../../components/modals/learn-about-custom-video-modal/learn-about-custom-video-modal.component';

@Component({
  selector: 'app-nexleader-group-settings',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgModelDebounceChangeDirective,
    FormsModule,
    NexleaderGroupPhotoUploadComponent,
    NexleaderInputEnumComponent,
    NexleaderGroupDirectPurchaseSettingsComponent
  ],
  templateUrl: './group-settings.component.html',
})
export class NexleaderGroupSettingsComponent implements OnInit {
  routeParams: any;
  group: any;
  onboardingPhoto: any;
  emails: any;
  groupAdmins: any[] = [];
  groupTiers: any[] = [];
  allVideoProviders: any;
  isSuperAdmin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private emailService: EmailService,
    private userService: UserService,
    private enumsService: EnumsService,
    private successService: SuccessService,
    private errorHandler: ErrorHandlerService,
    private blobService: BlobResourceService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routeParams = this.route.snapshot.params;

    // Check if the user is a super admin in order to show dangerous options
    const payload = this.authService.getTokenPayload();
    if (payload.roles) {
      this.isSuperAdmin = payload.roles.indexOf('admin') > -1;
    }
    this.load();
  }

  load(): void {
    const groupId = this.routeParams['group_id'];

    this.groupService
      .get(groupId)
      .pipe(
        tap((group) => {
          this.group = group;

          // Default the group's custom onboarding groupname
          if (this.group.customOnboarding) {
            this.group.customOnboarding.groupName = this.group.name;
            this.group.customOnboarding.videoProvider =
              this.group.customOnboarding.videoProvider || null;
          } else {
            this.group.customOnboarding = {
              groupName: this.group.name,
              videoProvider: null,
            };
          }
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();

    this.blobService
      .queryOnboardingPhoto(groupId)
      .pipe(
        tap((onboardingPhoto) => {
          this.onboardingPhoto = onboardingPhoto[0];
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();

    this.emailService
      .queryGroup(groupId)
      .pipe(
        tap((emails) => {
          this.emails = emails;
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();

    this.userService
      .queryGroup(groupId)
      .pipe(
        tap((users) => {
          this.groupAdmins = users.filter(
            (user) => user.roles[0] === 'groupadmin'
          );
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe();

    this.enumsService
      .getEnums()
      .pipe(
        tap((enums) => {
          this.groupTiers = enums.GroupTiers;
          this.allVideoProviders = enums.AllVideoProviders;
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  compareEnums(o1: any, o2: any) {
    if (o1?.name == o2?.name) return true;
    else return false;
  }

  saveEmail(email: any): void {
    this.emailService
      .save(email)
      .pipe(
        tap(() => {
          this.successService.handle();
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  saveGroup(): void {
    this.groupService
      .save(this.group)
      .pipe(
        tap(() => {
          this.successService.handle();
          this.load();
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  deleteUser(user: any): void {
    this.userService
      .delete(user._id)
      .pipe(
        tap(() => {
          this.load();
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe();
  }

  deleteGroup(): void {
    this.groupService
      .remove(this.group._id)
      .pipe(
        tap(() => {
          this.successService.handle();
          // Redirect to the home page since the group will no longer exist
          this.router.navigate(['/']);
        }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  makeLegacy(): void {
    this.groupService
      .makeLegacy(this.group)
      .pipe(
        tap((_) => { }),
        catchError((error) => {
          this.errorHandler.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  /**
   * toggleOnboardingVideoUpload()
   *
   * Toggles the ability for a group to upload a custom Vimeo video.
   */
  toggleOnboardingVideoUpload(): void {
    this.group.customOnboarding.videoEnabled =
      !this.group.customOnboarding.videoEnabled;
    this.saveGroup();
  }

  /**
   * toggleOnboarding()
   *
   * Toggles the ability for a group to add a custom onboarding section.
   */
  toggleOnboarding(): void {
    this.group.customOnboarding.enabledForGroup =
      !this.group.customOnboarding.enabledForGroup;
    this.saveGroup();
  }

  /**
   * openLearnAboutVideoModal()
   *
   * Opens up the learn about uploading a custom video modal.
   */
  openLearnAboutVideoModal(): void {
    const modalRef = this.modalService.show(
      NexleaderLearnAboutCustomVideoModalComponent
    );
  }

  /**
   * previewCustomOnboarding()
   *
   * We want to allow users to be able to previiew what their custom onboarding will
   *  look like, so we allow for them to view a modal of the custom onboarding
   *  rendered inside of it.
   */
  previewCustomOnboarding(): void {
    const modalRef = this.modalService.show(
      NexleaderCustomOnboardingPreviewModalComponent,
      {
        initialState: { groupId: this.group._id },
      }
    );
  }

  trackByGroupName(index: number, group: any) {
    return group.name;
  }
}

// angular.module('nexleader-ipsat').directive(
//   'ng17NexleaderGroupSettingsComponent',
//   downgradeComponent({
//     component: NexleaderGroupSettingsComponent,
//   }) as angular.IDirectiveFactory
// );
